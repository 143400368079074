<template>
  <auro-release-card class="auro-release__item" :release="release" @setStatus="setStatus" />
</template>

<script>
import customMutation from '@/queries/mutations/customMutation';
import { bus, preparePayload } from '@/helpers';
import AuroReleaseCard from './AuroReleaseCard';

export default {
  name: 'AuroRelease',

  components: {
    AuroReleaseCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    actionsMeta: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      isLoading: false,
      release: this.data.data,
    };
  },

  watch: {
    data() {
      this.release = { ...this.data.data };
    },
  },

  methods: {
    setStatus(data) {
      if (this.isLoading) return;
      this.isLoading = true;

      const fieldsMeta = this.actionsMeta.setStatus.fieldsList;
      this.$apollo
        .mutate({
          mutation: customMutation('setStatus', 'Release', fieldsMeta),
          variables: preparePayload(data, fieldsMeta),
        })
        .then(() => {
          this.isLoading = false;
          bus.$emit('refetchTable');
          this.$notification.success({
            message: this.$t('customForm.saveSuccess'),
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notification.error({
            message: this.$t('customForm.saveError'),
            description: error.message,
          });
        });
    },
  },
};
</script>
