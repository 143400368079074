<!-- witn no functional attribute ant dies, can't remember details already -->
<template functional>
  <a-sub-menu v-if="props.menuItem.type === 'section'" :key="props.menuItem.id">
    <span slot="title">
      <a-icon v-if="props.menuItem.icon" :type="props.menuItem.icon" />
      <span>{{ $options.title(props.menuItem) }}</span>
    </span>
    <a-menu-item-group>
      <sidebar-section v-for="item in props.menuItem.children" :key="item.id" :menuItem="item" />
    </a-menu-item-group>
  </a-sub-menu>
  <a-menu-item v-else :key="`${props.menuItem.type}:${props.menuItem.url || props.menuItem.code}`">
    <a-icon v-if="props.menuItem.icon" :type="props.menuItem.icon" />
    <span>{{ $options.title(props.menuItem) }}</span>
  </a-menu-item>
</template>

<script>
import store from '@/store';

export default {
  name: 'SidebarSection',
  props: {
    menuItem: {
      type: Object,
      required: true,
    },
  },
  title(menuItem) {
    return typeof menuItem.title === 'string'
      ? menuItem.title
      : menuItem.title[store.state.lang] || menuItem.code;
  },
};
</script>
