import gql from 'graphql-tag';

export default {
  query: gql`
    query History($entityName: String, $entityId: String) {
      history(entityName: $entityName, entityId: $entityId) {
        entries {
          id
          date
          userId
        }
      }
    }
  `,
};
