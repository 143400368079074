<template>
  <div :class="['tags', size, { ellipsis }]">
    <a-tag v-for="tag in list" :key="tag.value" class="tags__item" @click="onClick($event, tag)">
      {{ tag.title }}
    </a-tag>
  </div>
</template>

<script>
export default {
  name: 'BaseTags',
  props: {
    value: {
      type: [Array, String, Object],
      default: () => [],
    },
    size: {
      type: String,
      default: '',
    },
    ellipsis: {
      type: Boolean,
      default: true,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    list() {
      if (!this.value) return [];
      const list = Array.isArray(this.value) ? this.value : [this.value];
      return typeof list[0] === 'string' ? list.map((value) => ({ value, title: value })) : list;
    },
  },
  methods: {
    onClick(event, tag) {
      if (this.clickable) {
        event.stopPropagation();
        this.$emit('clickTag', tag.value);
      }
    },
  },
};
</script>

<style lang="scss">
.tags {
  margin-top: -10px;
  max-width: 400px;

  &__item.ant-tag {
    height: 30px;
    padding: 0 19px;
    border: none;
    line-height: 30px;
    margin-top: 10px;
    vertical-align: middle;
  }

  &.small {
    margin-bottom: -4px;
    margin-top: -13px;
  }

  &.small &__item {
    height: 28px;
    padding: 0 10px;
    line-height: 28px;
  }
}
</style>
