<template>
  <component :is="tag" :class="className" v-bind="attributes">
    <template v-for="(child, index) of element.child">
      <so-course-html v-if="child.node === 'element'" :key="index" :element="child" />
      <span v-else :key="index" v-html="child.text"></span>
    </template>
  </component>
</template>

<script>
export default {
  name: 'SoCourseHtml',

  props: {
    element: {
      type: [Object, String],
      required: true,
    },
  },

  computed: {
    tag() {
      // element.root has no tag
      return this.element.tag || 'p';
    },
    className() {
      return this.element.node === 'root' ? 'so-course-html' : '';
    },
    attributes() {
      return this.element.attr || {};
    },
  },

  methods: {},
};
</script>

<style lang="scss">
.so-course-html {
  * {
    overflow-wrap: break-word;
  }
  h3 {
    margin-bottom: 4px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 700;
    &:not(:first-child) {
      margin-top: 24px;
    }
  }

  h4 {
    margin-top: 24px;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 14px;
    font-weight: 700;
  }

  .video,
  img {
    margin-top: 24px;
  }

  p {
    margin-bottom: 0;
  }

  .ant-checkbox-wrapper {
    margin-left: 0%;
  }
}
</style>
