<template>
  <div :class="['color-plate', size, { disabled, readOnly }]" @click="$emit('click')">
    <div class="color-plate__fg" :style="plateStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'ColorPlate',
  props: {
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small',
    },
  },
  computed: {
    plateStyle() {
      return `background-color: ${this.color}`;
    },
  },
};
</script>

<style lang="scss">
.color-plate {
  height: 32px;
  border: 1px solid $defaultBorderColor;
  border-radius: 2px;
  cursor: pointer;

  &.disabled:not(.readOnly) {
    cursor: not-allowed;
  }

  &.readOnly {
    cursor: default;
  }

  background-color: #fff;
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
  background-image: linear-gradient(
      45deg,
      #ddd 25%,
      transparent 25%,
      transparent 75%,
      #ddd 75%,
      #ddd
    ),
    linear-gradient(45deg, #ddd 25%, transparent 25%, transparent 75%, #ddd 75%, #ddd);

  &__fg {
    height: 100%;
    pointer-events: none;
  }

  &.small {
    height: 24px;
    background-size: 22px;
    background-position: 0 0, 11px 11px;
  }
}
</style>
