import { render, staticRenderFns } from "./SoCourseHistory.vue?vue&type=template&id=0bc5d1e2&"
import script from "./SoCourseHistory.vue?vue&type=script&lang=js&"
export * from "./SoCourseHistory.vue?vue&type=script&lang=js&"
import style0 from "./SoCourseHistory.vue?vue&type=style&index=0&id=0bc5d1e2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports