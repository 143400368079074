<template>
  <a-form-item :validateStatus="resolveState" :help="errors[0]">
    <slot />
  </a-form-item>
</template>

<script>
export default {
  name: 'ErrorOutput',
  props: {
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    resolveState() {
      if (this.errors[0]) {
        return 'error';
      }
      return '';
    },
  },
};
</script>
