import store from '@/store';
import { bus, preparePayload } from '@/helpers';
import TABLE_QUERY from '@/queries/table';
import CREATE_MUTATION from '@/queries/mutations/create';
import UPDATE_MUTATION from '@/queries/mutations/update';
import DELETE_MUTATION from '@/queries/mutations/delete';
import BaseService from './BaseService';

class EntityService extends BaseService {
  sendRequest(mutation, variables, meta) {
    if (variables.data && meta) {
      variables.data = preparePayload(variables.data, meta);
    }

    return store.state.apolloClient.mutate({ mutation, variables });
  }

  async createOrUpdate(meta, variables) {
    if (variables.id.startsWith('_temp_')) {
      return this.create(meta, variables);
    }

    return this.update(meta, variables);
  }

  async create(meta, variables) {
    return new Promise((resolve, reject) => {
      this.sendRequest(CREATE_MUTATION, variables, meta)
        .then(({ data }) => {
          if (!data.create.errors.length) {
            store.mutate.deleteFormDraft(variables.type, variables.id);
            bus.$emit('entityCreated', data.create.document, variables.id);
          }

          resolve({
            isCreated: data.create.errors.length === 0,
            errors: data.create.errors,
            response: data.create.document,
          });
        })
        .catch((error) => {
          const message = store.state.transProvider.$t('entity.error.create');
          this.emitError(message, error.message);
          reject(new Error(error));
        });
    });
  }

  async update(meta, variables) {
    return new Promise((resolve, reject) => {
      this.sendRequest(UPDATE_MUTATION, variables, meta)
        .then(({ data }) => {
          if (!data.update.errors.length) {
            store.mutate.deleteFormDraft(variables.type, variables.id);
            bus.$emit('entityUpdated', data.update.document, variables.id);
          }

          resolve({
            isUpdated: data.update.errors.length === 0,
            errors: data.update.errors,
            response: data.update.document,
          });
        })
        .catch((error) => {
          const message = store.state.transProvider.$t('entity.error.update');
          this.emitError(message, error.message);
          reject(new Error(error));
        });
    });
  }

  delete(variables) {
    return new Promise((resolve, reject) => {
      this.sendRequest(DELETE_MUTATION, variables)
        .then(() => {
          store.mutate.deleteFormDraft(variables.type, variables.id);
          bus.$emit('entityDeleted', { type: variables.type }, variables.id);
          resolve({
            isDeleted: true,
          });
        })
        .catch((error) => {
          const message = store.state.transProvider.$t('entity.error.delete');
          this.emitError(message, error.message);
          reject(new Error(error));
        });
    });
  }

  getTable(variables) {
    return new Promise((resolve, reject) => {
      this.sendRequest(TABLE_QUERY.query, variables)
        .then(({ data }) => {
          const table = data.table.documents;
          resolve(table);
        })
        .catch((error) => {
          const message = store.state.transProvider.$t('entity.error.getTable');
          this.emitError(message, error.message);
          reject(new Error(error));
        });
    });
  }
}

export default new EntityService();
