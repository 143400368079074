var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['entity-form', _vm.size, { 'one-column': _vm.oneColumn }]},[_c('div',{staticClass:"entity-form__content"},[_vm._t("content",function(){return [(_vm.data)?_c('a-tabs',{class:[{ 'no-tabs': !_vm.hasTabs }],attrs:{"animated":false},model:{value:(_vm.activeTabKey),callback:function ($$v) {_vm.activeTabKey=$$v},expression:"activeTabKey"}},_vm._l((_vm.tabs),function(pane,index){return _c('a-tab-pane',{key:index,attrs:{"tab":pane.title}},[(pane.preview)?_c('div',{staticClass:"entity-form__tabpane preview"},_vm._l((pane.columns),function(column,columnIndex){return _c('div',{key:columnIndex,staticClass:"entity-form__column"},[_c('kanban-card',{attrs:{"fieldsToDisplay":column,"card":{ data: _vm.data },"showTitles":columnIndex > 0,"firstFieldBold":!columnIndex,"readOnly":"","fullView":"","parentType":_vm.type}})],1)}),0):_c('validation-observer',{ref:"validationObserver",refInFor:true,staticClass:"entity-form__tabpane"},_vm._l((pane.columns),function(column,columnIndex){return _c('div',{key:columnIndex,staticClass:"entity-form__column"},[_c('a-row',{attrs:{"gutter":16,"type":"flex"}},_vm._l((column),function(fieldDescriptor){return _c('a-col',{key:fieldDescriptor.name,attrs:{"sm":_vm.wideField(fieldDescriptor),"xs":24}},[(!fieldDescriptor.hidden)?_c('edit-form-field-wrap',{attrs:{"fieldDescriptor":fieldDescriptor,"label":fieldDescriptor,"parentType":_vm.type,"disabledRenderer":_vm.formConfig.params.renderDisabledFields.key,"disabled":_vm.disabled,"readOnly":_vm.readOnly,"size":_vm.size,"firstModalDepthLevel":""},on:{"updateEntity":_vm.updateEntity},model:{value:(_vm.data[fieldDescriptor.name]),callback:function ($$v) {_vm.$set(_vm.data, fieldDescriptor.name, $$v)},expression:"data[fieldDescriptor.name]"}}):_vm._e()],1)}),1)],1)}),0)],1)}),1):_vm._e()]}),_vm._t("bottomControls",null,null,{
        save: _vm.updateSelf,
        modelIntact: _vm.modelIntact,
        hasVisibleFields: _vm.hasVisibleFields,
        duplicate: _vm.duplicate,
        deleteSelf: _vm.deleteSelf,
        requestError: _vm.requestError,
      })],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }