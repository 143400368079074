import polyfills from './polyfills'; // eslint-disable-line
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import Antd from 'ant-design-vue';
import VScrollLock from 'v-scroll-lock';
import VClickOutside from 'v-click-outside';
import moment from 'moment-timezone';
import { bus } from '@/helpers';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { apolloClient } from './apolloClient';
import LocaleService, { i18n } from './services/LocaleService';

polyfills();

Vue.config.productionTip = false;
moment.locale('ru');

Vue.use(VueApollo);
Vue.use(Antd);
Vue.use(VScrollLock);
Vue.use(VClickOutside);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.mixin({
  methods: {
    reemit(eventName, args = []) {
      this.$emit(eventName, ...args);
    },
    emitError(message, description) {
      bus.$emit('error', {
        message,
        description,
      });
    },
  },
});

store.mutate.setApolloClient(apolloClient);

LocaleService.init().then(() => {
  new Vue({
    i18n,
    router,
    apolloProvider,
    render: (h) => h(App),
  }).$mount('#app');
});
