<template>
  <div :class="['entity-config', { oneColumn }]">
    <h3>{{ columnTitle }}</h3>
    <div class="entity-config__lists">
      <div v-for="(column, columnIndex) in columns" :key="columnIndex">
        <draggable-list
          v-model="columns[columnIndex]"
          class="entity-config__column"
          :options="{ handle: '.entity-config__header.top' }"
          group="config-form"
          @change="changeColumns($event, columnIndex)"
        >
          <entity-form-config-field
            v-for="(field, fieldIndex) in column"
            :key="getFieldKey(field, columnIndex)"
            v-model="column[fieldIndex]"
            class="entity-config__field"
            :label="field.name"
            @fieldUpdated="updateField(columnIndex, fieldIndex, $event)"
            @editFieldParams="reemit('editFieldParams', arguments)"
          />
        </draggable-list>
      </div>
    </div>
  </div>
</template>

<script>
import DraggableList from '@/components/base/DraggableList';
import EntityFormConfigField from './EntityFormConfigField';

export default {
  name: 'EntityFormConfigForm',

  components: {
    DraggableList,
    EntityFormConfigField,
  },

  props: {
    value: {
      type: [Object, Array],
      default: () => ({}),
    },
    oneColumn: {
      type: Boolean,
      default: false,
    },
    descriptors: {
      type: Array,
      required: true,
    },
    tabIndex: {
      type: Number,
      required: true,
    },
    formParams: {
      type: Object,
      required: true,
    },
  },

  computed: {
    columns: {
      get() {
        const compColumns = [...this.value.columns];

        if (!this.oneColumn && compColumns.length === 1) {
          compColumns.push([]);
        }

        return compColumns;
      },
      set(value) {
        this.$emit('input', {
          title: this.value.title,
          columns: value,
        });
      },
    },

    columnTitle() {
      if (this.oneColumn) return this.$t('entity.config.tab.embed.title');
      const localizationKey = ['entity.tab.primary', 'entity.tab.secondary'][this.tabIndex];
      return this.$t(localizationKey);
    },
  },

  methods: {
    updateField(columnIndex, fieldIndex, updatedData) {
      this.$set(this.columns[columnIndex], fieldIndex, updatedData);
      this.$emit('input', {
        ...this.value,
        columns: this.columns,
      });
    },

    changeColumns(event, columnIndex) {
      const { moved, added, removed } = event;
      const columns = [...this.columns];
      const column = columns[columnIndex];

      if (moved) {
        column.splice(moved.newIndex, 0, column.splice(moved.oldIndex, 1)[0]);
      }
      if (added) {
        column.splice(added.newIndex, 0, added.element);
      }
      if (removed) {
        column.splice(removed.oldIndex, 1);
      }

      this.columnsUpdate(columns);
    },

    columnsUpdate(columns) {
      this.columns = columns;
    },

    getDataForEmit(columns) {
      if (this.value.columns) {
        return { columns };
      }
      if (this.value.tabs) {
        return {
          tabs: [{ columns }],
        };
      }
      return columns[0];
    },

    getFieldKey(field, columnIndex) {
      return `${field.name}-${columnIndex}`;
    },
  },
};
</script>

<style lang="scss">
.entity-config {
  $bgColor: #f9f9f9;
  padding: 0 24px;
  margin: 0 -24px;

  &.oneColumn {
    margin-top: -18px;
    margin-right: 430px;
  }

  & > h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 14px;
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  &__params {
    margin: -10px -24px 10px;
    padding: 0 10px 15px;
  }

  &__lists {
    @include scrollbars();
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow-y: auto;

    & > div {
      display: inline-block;
      flex: 0 0 48%;
      width: 48%;
    }
  }

  &.oneColumn &__lists > div {
    flex: 0 0 100%;
    width: 100%;
  }

  &__column {
    width: 100%;
    height: 100%;
    min-height: 40px;

    & + & {
      margin-left: 10px;
    }
  }

  &__field {
    display: inline-flex;
    flex-direction: column;
    margin: 2px 0 4px;
    position: relative;
    height: 47px;
    width: 100%;

    &:hover .anticon-setting {
      opacity: 1;
    }
  }

  &__header {
    background-color: $bgColor;
    padding: 0 16px;
    height: 47px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: all-scroll;

    .anticon-drag {
      font-size: 13px;
      margin-right: 12px;
    }

    .anticon-setting {
      font-size: 23px;
      margin-left: 16px;
      opacity: 0;
      color: #d9d9d9;
    }

    .anticon-eye,
    .anticon-eye-invisible {
      cursor: pointer;
      font-size: 23px;
      margin-left: 16px;
      color: #d9d9d9;
    }

    &.top {
      h4 {
        width: 100%;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  &__config {
    padding: 7px 15px;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      display: inline-block;
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #7f7f7f;
      flex: 0 0 170px;
      overflow: hidden;
      padding-right: 10px;
      & + * {
        flex-grow: 1;
        min-width: 0;
        width: 100%;
      }
    }

    &--vertical {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 0;
      padding-bottom: 0;
      & + & {
        margin-top: 27px;
      }

      label {
        flex: 1 1 auto;
      }
    }

    &--wide {
      width: 100%;
    }

    .ant-checkbox-wrapper h3 {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    .edit-form-checkbox {
      margin-bottom: -12px;
    }
  }

  &__config--locale,
  &__config--locale-head {
    label {
      flex: 0 0 16%;
    }
    label + * {
      flex: 0 0 30.3%;
    }
    label + * + * {
      flex: 0 0 49.7%;
    }
  }

  &__config--locale {
    padding-top: 12px;
    &:not(:last-child) {
      padding-bottom: 12px;
    }
    &:last-child {
      padding-bottom: 0;
      margin-bottom: -15px;
    }

    label:first-child {
      line-height: 32px;
      margin-bottom: 0;
      font-weight: 500;
      color: initial;
    }
  }

  &__config--locale-head {
    margin-bottom: -20px;
  }

  &__add {
    background-color: $bgColor;
    display: flex;
    padding: 10px;
    flex-shrink: 0;
    button {
      margin-left: 5px;
      i {
        margin-right: 5px;
        margin-left: 5px;
      }
    }

    .ant-input-group {
      display: flex;
    }

    .ant-select-selection--single .ant-select-selection__rendered {
      margin-right: 30px;
    }

    &-column span {
      display: inline;
    }
  }

  &__remove-column {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
