<template>
  <div class="entity-modal">
    <entity-view
      v-if="contentVisible"
      :id="id"
      :type="type"
      :dataProcessor="dataProcessor"
      isModal
      @goBack="close"
    >
      <template #additionalHeadControls>
        <a-icon
          class="entity-view__head-button"
          :type="fullscreenIcon"
          @click="editFullEntity"
        />
      </template>
    </entity-view>
  </div>
</template>

<script>
import EntityView from './EntityView.vue';

export default {
  name: 'EntityModal',
  components: {
    EntityView,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      required: true,
    },
    dataProcessor: {
      type: Function,
      default: null,
    },
    contentVisible: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    fullscreenIcon() {
      return this.fullscreen ? 'fullscreen-exit' : 'fullscreen';
    },
  },

  methods: {
    editFullEntity() {
      this.$emit('editFullEntity');
    },

    editEntity(...args) {
      this.$emit('editEntity', ...args);
    },

    close() {
      this.$emit('close');
    },

    duplicateEntity(type, id) {
      this.$emit('editEntity', type, id);
    },
  },
};
</script>

<style lang="scss">
.entity-modal {
  @include scrollbars();
  width: 100%;
  height: 100%;
  padding: 0;
  overflow-y: scroll;
  background-color: #f9f9f9;

  &.noscroll {
    overflow-y: hidden;
    // scrollbar placeholder
    border-right: 8px solid $scrollBgColor;

    .embed-table__scroll-container {
      overflow: hidden;
      &.hasHorizontalOverflow {
        // scrollbar placeholder
        border-bottom: 8px solid $scrollBgColor;
      }
    }
  }

  &__spin.ant-spin {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    background: #fff7;

    &.ant-spin-spinning {
      position: absolute;
      display: flex;
      z-index: 20;
    }
  }

  .entity-view__head {
    background-color: #f9f9f9;
    position: sticky;
    z-index: 21;
    top: 0;
    padding-top: 25px;
  }

  .entity-form__footer {
    position: sticky;
    bottom: 0;
    padding-bottom: 40px;
    margin-bottom: -40px;
    background-color: #f9f9f9;
    z-index: 20;
  }

  .table-edit-popover .ant-popover-inner-content {
    padding: 0;
  }
  .editable-cell {
    margin: 0;
    padding: 6px 16px 7px;
  }

  &__forms {
    display: flex;
    width: 100%;
  }
}

.edit-form-spinner {
  width: 100%;
}

@media (min-width: $desktopBreakpoint) {
  .entity-modal {
    $max-form-width: 1400px;

    .entity-view {
      padding-right: 20px;
    }

    .entity-view__buttons {
      top: 25px !important;
    }
  }
}
</style>
