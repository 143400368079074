<template>
  <div
    :class="[
      'entity-field',
      { hidden: !loaded, required: field.required },
      'renderer--' + field.renderer,
    ]"
  >
    <div v-if="showTitle || showTitleAppendix" class="entity-field__head">
      <a-tooltip v-if="showTitle" placement="bottomLeft">
        <template slot="title">
          {{ field.name }}
        </template>
        <h3 class="entity-field__title ellipsis">{{ field.label }}</h3>
      </a-tooltip>
      <a-tooltip v-if="showTitle && field.info" placement="bottomLeft" class="entity-field__info">
        <template slot="title">{{ field.info }}</template>
        <a-icon type="question-circle" />
      </a-tooltip>
      <slot v-if="showTitleAppendix" name="afterTitle"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'EditFormField',

  props: {
    field: {
      type: Object,
      required: true,
    },
    loaded: {
      type: Boolean,
      default: true,
    },
    jsonView: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    showTitleAppendix: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    showTitle() {
      return !this.hideTitle && !['checkbox', 'hidden'].includes(this.field.renderer);
    },
  },
};
</script>

<style lang="scss">
.entity-field {
  width: 100%;
  margin-bottom: 30px;

  h3 {
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
    max-width: 100%;
  }

  &__head {
    display: flex;
    button + button {
      margin-left: 10px;
    }

    .ant-pagination {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -3px;
    }

    .embed-table__array-create {
      transform: translateY(-2px);
    }
  }

  &.required &__head:after {
    margin: 0 10px 0 -5px;
    font-weight: 500;
    content: '*';
  }

  &__title-buttons {
    display: inline-block;
    white-space: nowrap;
    position: relative;
    top: -2px;
  }

  &__info {
    height: 22px;
    transform: translate(-4px, 3px);
    padding: 4px;
    opacity: 0.5;
  }

  &__bottom-buttons {
    margin-top: 15px;
  }

  &.hidden {
    opacity: 0;
  }

  .tiles {
    margin-top: 0;
  }

  .table,
  .kanban {
    margin-top: 15px;
  }

  .ant-form-item-children-icon {
    visibility: hidden;
  }

  & > .ant-form-item {
    margin-bottom: 0;
  }
}

@media (min-width: $tabletBreakpoint) {
  .entity-field {
    &__title {
      h3 {
        margin-right: 20px;
      }
    }
  }
}
</style>
