<template>
  <div ref="map" class="page-map" :style="containerStyle"></div>
</template>

<script>
import Vue from 'vue';
import { uniqueId, prepareKanbanCardLayout } from '@/helpers';
import { appSettings } from '@/AppSettings';
import KanbanCard from '@/components/page/kanban/KanbanCard';

export default {
  name: 'TheMap',
  props: {
    config: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    entityFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      mapId: uniqueId('map'),
      map: null,
      mapsInfowindow: null,
      clusterer: null,
      markers: [],
      defaultMapCenterCoords: {
        lat: 55.75368949910336,
        lng: 37.619910616697794,
      },
    };
  },
  computed: {
    containerStyle() {
      return {
        width: '100%',
        height: '600px',
      };
    },
    fieldsToDisplay() {
      return prepareKanbanCardLayout(this.entityFields, this.config.displayFields);
    },
    infoWindowPropData() {
      return {
        card: this.activeItem,
        fieldsToDisplay: this.fieldsToDisplay,
        showTitles: true,
        readOnly: true,
      };
    },
  },
  watch: {
    markersCoords() {
      this.setMarkerPosition();
    },
  },
  mounted() {
    this.loadApi();
  },
  methods: {
    loadApi() {
      if (!window.google?.maps) {
        const script = document.createElement('script');
        script.async = true;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${appSettings.get(
          'gmapsApiKey',
        )}&callback=initMap`;

        window.initMap = () => {
          this.initMap();
        };

        document.head.appendChild(script);
      } else {
        this.initMap();
      }
    },
    initMap() {
      this.map = new window.google.maps.Map(this.$refs.map, {
        center: this.defaultMapCenterCoords,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        zoom: 3,
      });

      this.mapsInfowindow = new window.google.maps.InfoWindow();
      this.createMarkers();
      this.initMarkersSpiderfier();
    },
    createMarkers() {
      this.data.rows.forEach((item) => {
        let coords = item.data[this.config.displayType.options.coordsField] || '';
        coords = coords.replace(/[()]/g, '').replace(/\s/g, '').split(',');

        if (coords.length === 2) {
          const marker = new window.google.maps.Marker({
            customInfo: item,
            position: {
              lat: Number(coords[0]),
              lng: Number(coords[1]),
            },
            map: this.map,
          });

          this.markers.push(marker);
        }
      });
    },
    initMarkersSpiderfier() {
      if (!window.OverlappingMarkerSpiderfier) {
        import(`overlapping-marker-spiderfier`).then(({ default: OverlappingMarkerSpiderfier }) => {
          window.OverlappingMarkerSpiderfier = OverlappingMarkerSpiderfier;
          this.createMarkersSpiderfier();
        });
      } else {
        this.createMarkersSpiderfier();
      }
    },
    createMarkersSpiderfier() {
      this.markerSpiderfier = new window.OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
      });

      this.markerSpiderfier.addListener('click', (marker) => {
        this.showMarkerPopup(marker);
      });

      this.markers.forEach((marker) => {
        this.markerSpiderfier.addMarker(marker);
      });

      this.markerSpiderfier.addListener('spiderfy', () => {
        this.mapsInfowindow.close();
      });
    },
    showMarkerPopup(marker) {
      this.mapsInfowindow.setContent('<div id="mountHere"></div>');
      this.mapsInfowindow.open(this.map, marker);

      this.$nextTick(() => {
        const Component = Vue.extend(KanbanCard);
        const componentInstance = new Component({
          propsData: {
            card: marker.customInfo,
            fieldsToDisplay: this.fieldsToDisplay,
            showTitles: true,
            readOnly: true,
          },
        });
        componentInstance.$mount('#mountHere');
      });
    },
  },
};
</script>

<style lang="scss">
.page-map {
  .kanban-card {
    padding: 0px 5px 0 0;
    background: none;
  }
}
</style>
