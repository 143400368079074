<template>
  <error-output :errors="errors">
    <a-select
      v-model="input"
      class="filter-dropdown"
      :name="config.name"
      :placeholder="placeholder"
      allowClear
      @change="$emit('input', $event)"
      @blur="$emit('blur')"
    >
      <a-select-option v-for="option of config.options" :key="option.value" :value="option.value">
        {{ option.title || option.value }}
      </a-select-option>
    </a-select>
  </error-output>
</template>

<script>
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'EditFormDropdown',
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    placeholder() {
      return this.disabled || this.readOnly ? '' : this.$t(`base.placeholder.select`);
    },
  },
};
</script>

<style lang="scss">
.filter-dropdown {
  width: 100%;
}
</style>
