<template>
  <error-output :errors="errors">
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label
      ref="label"
      :class="['autocomplete', { focused, disabled, readOnly }]"
      @click="onLabelClick"
    >
      <div class="autocomplete__wrapper">
        <span
          v-for="(item, index) of items"
          :key="index"
          class="autocomplete__value-item"
        >
          {{ idAsLabel ? item.key.split(':')[1] : item.label }}
          <a-icon
            v-if="!disabled && !readOnly"
            type="close"
            @click.prevent="removeRef(index)"
          />
        </span>
        <a-select
          v-if="!readOnly"
          ref="focusOnMount"
          v-model="items"
          showSearch
          labelInValue
          class="autocomplete__input"
          :getPopupContainer="getPopupContainer"
          :defaultActiveFirstOption="false"
          :filterOption="false"
          :disabled="disabled"
          :mode="selectMode"
          @search="onSearch"
          @focus="focused = true"
          @blur="focused = false"
          @dropdownVisibleChange="onDropdownVisibleChange"
        >
          <a-spin
            v-if="loadingKeys"
            slot="notFoundContent"
            size="small"
          />
          <div
            v-else
            class="autocomplete__nodata"
            slot="notFoundContent"
          >
            {{ $t('base.autocomplete_notFoundContent') }}
          </div>
          <a-select-option
            v-for="option of options"
            :key="idAsLabel ? `${option.value}::${option.title}` : option.value"
          >
            {{ idAsLabel ? option.value.split(':')[1] : option.title }}
          </a-select-option>
        </a-select>
      </div>
    </label>
  </error-output>
</template>

<script>
import { appSettings } from '@/AppSettings';
import SEARCH_QUERY from '@/queries/search';
import ErrorOutput from '@/components/base/ErrorOutput';
import focusOnMount from '@/components/base/focusOnMount.mixin';
import lockScroll from '@/components/base/lockScroll.mixin';
import scrollElementInView from '@/components/base/scrollElementInView.mixin';

export default {
  name: 'RefAutocomplete',

  mixins: [focusOnMount, lockScroll, scrollElementInView],

  components: {
    ErrorOutput,
  },

  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, String, Array],
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    idAsLabel: {
      type: Boolean,
      default: false,
    },
    firstModalDepthLevel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      focused: false,
      enableSearch: false,
      loadingKeys: 0,
    };
  },

  computed: {
    multiple() {
      return this.config.config ? this.config.config.multiple : this.config.renderer === 'refs';
    },
    items: {
      get() {
        let value = this.value;

        if (typeof value === 'string') value = { value, title: value };
        if (value && !this.multiple && !Array.isArray(value)) value = [value];

        return (value || []).map((option) => ({
          key: option.value,
          label: option.title,
        }));
      },
      set(value) {
        let emited = value;

        if (this.idAsLabel) {
          if (Array.isArray(emited) && emited.length) {
            emited = emited[0];
          }

          if (!Array.isArray(emited)) {
            const key = emited.key.split('::')[0];
            emited.label = emited.key.split('::')[1];
            emited.key = key;
          }
        }

        if (this.multiple) {
          emited = emited.map((option) => ({
            value: option.key,
            title: option.label.trim(),
          }));
        } else if (emited) {
          if (Array.isArray(emited)) {
            emited = emited[0] || null;
          }

          emited = emited && {
            value: emited.key,
            title: emited.label.trim(),
          };

          if (this.config.renderer === 'refs') {
            emited = emited ? [emited] : [];
          }
        }

        this.$emit('input', emited);
      },
    },
    selectMode() {
      return this.multiple ? 'multiple' : 'default';
    },
    options() {
      if (this.loadingKeys || !this.enableSearch) {
        return [];
      }

      return this.config.types.reduce(
        (acc, type) =>
          acc.concat(
            this.$data[`autocomplete${type}`]?.map((option) => ({
              title: option.title,
              value: `${type}:${option.value}`,
            })) || [],
          ),
        [],
      );
    },
  },

  created() {
    this.config.types
      .filter((type) => !type.startsWith(appSettings.get('systemFieldsPrefix')))
      .forEach((type) => {
        this.$apollo.addSmartQuery(`autocomplete${type}`, {
          ...SEARCH_QUERY,
          loadingKey: 'loadingKeys',
          fetchPolicy: 'network-only',
          notifyOnNetworkStatusChange: true,
          variables: {
            type,
            value: this.search,
            first: 50,
          },
          skip() {
            return !this.enableSearch;
          },
          error() {
            this.emitError(this.$t(`base.autocompleteErrorOptions`, { field: this.config.field }));
          },
        });
      });
  },

  methods: {
    async setFocus() {
      if (!this.multiple || !this.items.length) {
        if (this.multiple) {
          this.$refs.focusOnMount.$el.querySelector('input').click();
        } else {
          this.$refs.focusOnMount.$el.click();
        }
      }
    },
    async onDropdownVisibleChange(visible) {
      if (visible) {
        this.enableSearch = true;
        if (this.firstModalDepthLevel) {
          await this.scrollElementInView(this.$refs.wrapper, this.getMarginsByRenderer('form_ref'));

          this.lockEntityFormScroll();
        }
      } else {
        this.lockEntityFormScroll(false);
        this.onSearch('');
      }
    },
    getPopupContainer() {
      return this.$refs.label;
    },
    onSearch(value) {
      this.search = value;
      this.config.types.forEach((type) => {
        this.$apollo.queries[`autocomplete${type}`].refetch({
          type,
          value: this.search,
        });
      });
    },
    onLabelClick(event) {
      // Для не multiple селектов, чтобы не открывались повторно
      if (this.focused) event.preventDefault();
    },
    removeRef(index) {
      const refItems = [...this.items];
      refItems.splice(index, 1);
      this.items = refItems;
    },
    getLabel(option, key) {
      return this.idAsLabel ? option[key].split(':')[1] : option.title;
    },
  },
};
</script>
