<template>
  <error-output :errors="errors">
    <a-checkbox-group
      v-model="items"
      :disabled="disabled || readOnly"
      :class="['checkboxes readOnly', { readOnly }]"
      :options="selectOptions"
    />
  </error-output>
</template>

<script>
import { parseJson } from '@/helpers';
import ErrorOutput from '@/components/base/ErrorOutput';

export default {
  name: 'RefCheckboxes2',
  components: {
    ErrorOutput,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, Array],
      default: undefined,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    parentType: {
      type: String,
      default: 'default',
    },
  },
  data() {
    return {
      loadingKeys: 0,
      options: [],
    };
  },
  computed: {
    multiple() {
      return this.config.config.multiple;
    },
    items: {
      get() {
        let value = this.value;
        if (value && !this.multiple) value = [value];
        return (value || []).map((item) => item.value);
      },
      set(value) {
        let emited = value;
        if (this.multiple) {
          emited = emited.map((option) => ({
            value: option,
            title: '',
          }));
        } else if (emited) {
          emited = emited.filter((v) => !this.items.includes(v))[0] || null;
          emited = emited && {
            value: emited,
            title: '',
          };
        }

        this.$emit('input', emited);
      },
    },
    selectOptions() {
      return this.options.map(this.getOptionKeyAndTitleForSelect);
    },
  },
  created() {
    this.loadingKeys++;
    let url = this.config.config.url;
    if (this.search) url += `?search=${this.search}`;

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        this.loadingKeys--;
        if (xhr.status === 200) {
          let options = parseJson(xhr.response);
          if (this.parentType === 'User' && this.config.name === 'roles') {
            options = options.filter((item) => item !== 'ROLE_AUTHOR');
          }

          this.options = options;
        } else {
          this.emitError(this.$t(`base.autocompleteErrorOptions`, { field: this.config.field }));
        }
      }
    };

    xhr.open('GET', url, true);
    xhr.send();
  },
  methods: {
    getOptionKeyForSelect(rawValue) {
      if (typeof rawValue === 'string') {
        return rawValue;
      }

      if (
        Object.keys(rawValue).length === 2 &&
        'title' in rawValue &&
        'value' in rawValue &&
        typeof rawValue.value === 'string'
      ) {
        return rawValue.value;
      }

      return rawValue.title;
    },
    getOptionKeyAndTitleForSelect(rawValue) {
      return {
        x: 6,
        label: typeof rawValue === 'string' ? rawValue : rawValue.title,
        value: this.getOptionKeyForSelect(rawValue),
      };
    },
  },
};
</script>
