<template>
  <div class="the-page">
    <component :is="customComponent" v-if="customComponent" />
    <page-block
      v-for="(block, index) of blocks"
      :key="`${page.url}${block}`"
      :pageUrl="page.url"
      :entityType="block"
      :showSidebarButton="index === 0"
      @entityClick="editEntity"
    />
  </div>
</template>

<script>
import store from '@/store';
import PageBlock from './pageblock/PageBlock.vue';
import Log from './log/TheLog';

export default {
  name: 'ThePage',
  components: { PageBlock, Log },

  computed: {
    page() {
      return store.state.activeSidebarItem || {};
    },
    blocks() {
      return this.page.code ? [this.page.code] : [];
    },
    customComponent() {
      return store.state.activeSidebarItem?.customprops?.customPage;
    },
  },

  methods: {
    editEntity(...args) {
      this.$emit('editEntity', ...args);
    },
  },
};
</script>

<style lang="scss">
.the-page {
  padding-bottom: 40px;
  padding-left: 10px;
  padding-right: 10px;
  overflow-x: visible;
}

@media (min-width: $desktopBreakpoint) {
  .the-page {
    @include scrollbars();
    height: 100%;
    overflow-y: scroll;

    &.noscroll {
      overflow-y: hidden;
    }
  }

  .menu--vertical .the-page {
    padding-left: 40px;
    padding-right: 26px;

    &.noscroll {
      padding-right: 34px;
    }
  }

  .menu--horizontal .the-page {
    padding-left: 24px;
    padding-right: 24px;

    &.noscroll {
      padding-right: 32px;
    }
  }
}
</style>
