<template>
  <div class="pageblock-config" :class="{ 'pageblock-config--wide-labels': wideLabels }">
    <div v-for="(field, key) in commonFields" :key="key" class="pageblock-config__field">
      <div class="pageblock-config__field-label ellipsis">
        {{ label(field) }}
      </div>
      <div class="pageblock-config__field-value">
        <a-input
          v-if="field.renderer === 'string'"
          v-model="value[key]"
          autocomplete="off"
          size="small"
          @change="configUpdated"
        />

        <input-number
          v-else-if="field.renderer === 'number'"
          v-model="value[key]"
          :emptyValue="0"
          autocomplete="off"
          size="small"
          @change="configUpdated"
        />

        <a-checkbox
          v-else-if="field.renderer === 'checkbox'"
          v-model="value[key]"
          @change="configUpdated"
        />

        <a-select
          v-else-if="field.renderer === 'enum'"
          v-model="value[key]"
          size="small"
          :allowClear="!field.nonEmpty"
          :options="field.options(entityFields, entityType)"
          @change="configUpdated"
        />

        <div v-else-if="field.renderer === 'conditional-form'">
          <a-select
            v-model="value[key].value"
            size="small"
            :options="getEnumFieldOptions(field, entityType)"
            @change="updateConditionalFormDefaults(key, $event)"
          />
        </div>
      </div>

      <page-block-config-form
        v-if="field.renderer === 'conditional-form'"
        v-model="value[key].options"
        :configLayout="getSubFormLayoutFromList(field.options, value[key].value)"
        :entityFields="entityFields"
        @configUpdated="configUpdated"
      />
    </div>
  </div>
</template>

<script>
import InputNumber from '@/components/base/InputNumber.vue';
import configFormMixin from '@/components/page/config/configFormMixin';

export default {
  name: 'PageBlockConfigForm',
  mixins: [configFormMixin],
  components: { InputNumber },

  props: {
    wideLabels: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    commonFields() {
      const commonFields = {};
      const notAllowedKeys = ['displayType', 'displayFields'];
      Object.entries(this.configLayout).forEach(([key, field]) => {
        if (!notAllowedKeys.includes(key)) {
          commonFields[key] = field;
        }
      });
      return commonFields;
    },
  },

  methods: {
    label(field) {
      return this.$t(field.localizationKey);
    },
  },
};
</script>

<style lang="scss">
.pageblock-config {
  flex: 0 0 100%;

  &__field {
    display: flex;
    flex-wrap: wrap;

    &-label {
      flex: 1 1 28%;
      padding-right: 20px;
      font-weight: 500;
    }

    &-value {
      flex: 0 0 72%;
    }

    & + & {
      margin-top: 20px;
    }
  }

  &--wide-labels {
    .pageblock-config__field {
      &-label {
        flex: 1 1 40%;
        padding-right: 20px;
        font-weight: 500;
      }

      &-value {
        flex: 0 0 60%;
      }
    }
  }

  .pageblock-config {
    .pageblock-config__field:first-child {
      margin-top: 20px;
    }
  }

  .ant-select,
  .ant-input-number {
    width: 100%;
  }
}
</style>
