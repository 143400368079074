var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.loading > 0}},[(!_vm.resetEditor)?_c('MonacoEditor',{directives:[{name:"show",rawName:"v-show",value:(_vm.showEditor),expression:"showEditor"}],ref:"editor",class:['editor', { disabled: _vm.disabled }],style:(_vm.editorStyle),attrs:{"diffEditor":!!_vm.diffValue,"original":_vm.diffInput,"options":{
      BuiltinTheme: 'vs-dark',
      automaticLayout: true,
      readOnly: _vm.disabled || _vm.readOnly,
      language: _vm.language,
      scrollbar: {
        alwaysConsumeMouseWheel: false,
      },
      suggest: {
        showKeywords: false,
        showModules: false,
      },
    }},on:{"editorWillMount":_vm.registerLang,"editorDidMount":_vm.onEditorMounted},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }