<template>
  <edit-form-embed-renderer
    v-if="!subConfigsLoading && cachedFormConfigs"
    v-bind="rendererBindings"
    :inlineFormControls="inlineFormControls"
    v-on="rendererEvents"
  />
</template>

<script>
import store from '@/store';
import { bus, isSystemField } from '@/helpers';
import FormConfigService from '@/services/FormConfigService';
import EditFormEmbedRenderer from './EditFormEmbedRenderer';

export default {
  name: 'EditFormEmbed',
  components: { EditFormEmbedRenderer },

  props: {
    parentType: {
      type: String,
      required: true,
    },
    parentFocusId: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Array, Object],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    embedLevel: {
      type: Number,
      default: 0,
    },
    embedParentRowId: {
      type: String,
      default: null,
    },
    embedParentField: {
      type: String,
      default: null,
    },
    dragClassname: {
      type: String,
      default: null,
    },
    inlineFormControls: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      page: 1,
      subConfigsLoading: 0,
      cachedFormConfigs: null,
    };
  },

  computed: {
    pageSize() {
      if (this.embedLevel) {
        return Number.MAX_SAFE_INTEGER;
      }

      // non-multiple embeds do not have pageSize parameter
      return this.config.pageSize || 1;
    },

    pagerData() {
      const totalCount = this.parentData.length;
      const totalPages = Math.max(1, Math.ceil(totalCount / this.pageSize));

      return {
        pageSize: this.pageSize,
        totalCount,
        totalPages,
        hasMore: this.page <= totalPages,
      };
    },

    dataRows() {
      const from = (this.page - 1) * this.pageSize;
      const to = from + this.pageSize;

      return this.parentData.slice(from, to);
    },

    multiple() {
      return this.config.multiple;
    },

    focusId() {
      return `${this.parentFocusId}${this.parentFocusId ? '-' : ''}${this.config.name}`;
    },

    parentData: {
      get() {
        let value = this.value || [];
        if (!this.multiple && !this.embedLevel) {
          value = [value[0] || { type: this.config.types[0], data: {} }];
        }

        value.forEach((item, index) => {
          item.id = String(index);
          item.data = item.data || {};
          item.title = item.data.title;
        });

        // Filter out service fields for display in models (EntityConfig) forms
        if (this.config.name === 'fields' && this.parentType === 'EntityConfig') {
          value = value.filter((item) => !isSystemField(item.data));
        }

        return value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    rendererBindings() {
      return {
        ...this.$props,
        subConfigsLoading: this.subConfigsLoading,
        cachedFormConfigs: this.cachedFormConfigs,
        dataRows: this.dataRows,
        pagerData: this.pagerData,
        page: this.page,
        multiple: this.multiple,
        parentType: this.parentType,
        focusId: this.focusId,
        embedLevel: this.embedLevel,
        embedParentRowId: this.embedParentRowId,
        embedParentField: this.embedParentField,
        dragClassname: this.dragClassname,
        disabled: this.disabled,
        readOnly: this.readOnly,
      };
    },

    rendererEvents() {
      return {
        switchEntityType: this.switchEntityType,
        createItem: this.createItem,
        createNestedItem: this.createNestedItem,
        deleteItem: this.deleteItem,
        moveItem: this.moveItem,
        updateItem: this.updateItem,
        changePage: this.changePage,
        updateForAutocomplete: this.updateForAutocomplete,
      };
    },
  },

  created() {
    this.cachedFormConfigs = this.config.types.reduce((acc, type) => {
      acc[type] = FormConfigService.getEmbedConfig(type);
      return acc;
    }, {});
  },

  methods: {
    changePage(page) {
      this.page = page;
    },

    getNewItem(type, id) {
      return {
        type,
        data: {},
        id: String(id),
      };
    },

    createItem(type) {
      const item = this.getNewItem(type, this.parentData.length);
      this.setAutofocusField(item, type);
      this.parentData = [...this.parentData, item];

      this.$nextTick(() => {
        this.page = this.pagerData.totalPages;
      });
    },

    createNestedItem(type, columnName, rowId) {
      const row = this.parentData.find((r) => r.id === rowId);
      const item = this.getNewItem(type, row.data[columnName]?.length || 0);
      this.setAutofocusField(item, type, row, columnName);

      if (!row.data[columnName]) {
        row.data[columnName] = [item];
        this.parentData = this.parentData.slice();
      } else {
        row.data[columnName].push(item);
      }
    },

    /**
     * Store data for autofocus on table mount/update
     */
    setAutofocusField(item, type, parentRow = null, parentColumn = null) {
      const level = this.embedLevel + (parentRow ? 1 : 0);
      let column;
      let id;

      if (parentRow) {
        const meta = this.config.typesDict[parentRow.type].fields.find(
          ({ name }) => name === parentColumn,
        ).typesDict[type];

        const embedConfig = FormConfigService.getEmbedConfig(type, meta.fields, true);
        column = embedConfig.columns.find(({ name, hidden }) => {
          const fieldMeta = meta.fields.find((f) => f.name === name);
          return (
            !hidden &&
            !isSystemField({ types: fieldMeta.types }) &&
            !['embed', 'boolean', 'uuid', 'hidden'].includes(fieldMeta.renderer)
          );
        })?.name;

        id = `${this.focusId}-${parentRow.id}-${parentColumn}-${item.id}-${column}`;
      } else {
        const fields = this.cachedFormConfigs[type].columns;
        column = fields.find(({ name, hidden }) => {
          const fieldMeta = this.config.typesDict[type].fields.find((f) => f.name === name);

          return (
            !hidden &&
            !isSystemField({ types: fieldMeta.types }) &&
            !['embed', 'boolean', 'uuid', 'hidden'].includes(fieldMeta.renderer)
          );
        })?.name;

        id = `${this.focusId}-${item.id}-${column}`;
      }

      if (column) {
        store.state.embedAutofocus = {
          id,
          parentRowId: parentRow && String(parentRow.id),
          parentField: this.embedParentField || this.config.name,
          rowId: String(item.id),
          level,
          column,
        };
      }
    },

    deleteItem(row, nestedColumn) {
      if (nestedColumn) {
        row = this.parentData.find((item) => item.id === row.id);
        row.data[nestedColumn] = null;
      } else {
        this.parentData = this.parentData.filter((item) => item.id !== row.id);
        this.$nextTick(() => {
          this.page = Math.max(1, Math.min(this.page, this.pagerData.totalPages));
        });
      }
    },

    moveItem(row, posShift) {
      const movedRowPos = this.parentData.findIndex((item) => item.id === row.id);
      const reorderedData = this.parentData.slice();
      const movedItem = reorderedData.splice(movedRowPos, 1)[0];
      reorderedData.splice(movedRowPos + posShift, 0, movedItem);
      this.parentData = reorderedData;
    },

    updateForAutocomplete(value) {
      this.$emit('input', value);
    },

    updateArray(value) {
      this.parentData = value;
    },

    updateItem(row, field, value) {
      row = this.parentData.find((item) => item.id === row.id);
      row.data[field] = value;
      this.parentData = this.parentData.slice();
    },

    switchEntityType(type) {
      bus.$emit('switchEntityType', this.config.field);
      this.$nextTick(() => {
        this.updateArray([{ type, data: {} }]);
      });
    },
  },
};
</script>
