<template>
  <a-select
    v-model="input"
    class="input-autocomplete"
    :size="size"
    :filterOption="false"
    :defaultActiveFirstOption="false"
    allowClear
    showSearch
    labelInValue
    :placeholder="$t('base.placeholder.select')"
    :notFoundContent="loading ? undefined : null"
    :options="selectOptions"
    :disabled="disabled"
    @search="onSearch"
  >
    <a-spin
      v-if="loading"
      slot="notFoundContent"
      size="small"
    />
  </a-select>
</template>

<script>
import { parseJson } from '@/helpers';

export default {
  name: 'FilterElementAutocomplete2',

  props: {
    config: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      options: [],
      value: undefined,
    };
  },

  computed: {
    selectOptions() {
      return this.options.map(this.getOptionKeyAndTitleForSelect);
    },

    input: {
      get() {
        return this.value ? { key: this.value.value, label: this.value.title } : undefined;
      },
      set(option) {
        option =
          (option && {
            value: option.key,
            title: option.label,
          }) ||
          undefined;
        this.value = option;

        option =
          option && this.options.find((opt) => this.getOptionKeyForSelect(opt) === option.value);
        this.$emit('input', option);
      },
    },

    loading() {
      return this.loadingKeys > 0;
    },
  },

  created() {
    this.updateOptions();
  },

  methods: {
    onSearch(value) {
      this.search = value;
      this.updateOptions();
    },

    updateOptions() {
      this.loadingKeys++;
      let url = this.config.config.url;
      if (this.search) url += `?search=${this.search}`;

      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          this.loadingKeys--;
          if (xhr.status === 200) {
            this.options = parseJson(xhr.response);
          } else {
            this.emitError(this.$t(`base.autocompleteErrorOptions`, { field: this.config.field }));
          }
        }
      };

      xhr.open('GET', url, true);
      xhr.send();
    },

    getOptionKeyForSelect(rawValue) {
      if (typeof rawValue === 'string') {
        return rawValue;
      }

      if (
        Object.keys(rawValue).length === 2 &&
        'title' in rawValue &&
        'value' in rawValue &&
        typeof rawValue.value === 'string'
      ) {
        return rawValue.value;
      }

      return rawValue.title;
    },

    getOptionKeyAndTitleForSelect(rawValue) {
      return {
        title: this.getOptionLabel(
          typeof rawValue === 'string' ? rawValue : rawValue.title,
          this.config,
        ),
        key: this.getOptionKeyForSelect(rawValue),
      };
    },

    getOptionLabel(value, { config }) {
      let display = value;
      const type = config?.url?.replace(/.*?\/handbooks\/(.*?)\/.*/, '$1');
      if (type) {
        const transKey = `handbooks.${type}.${value}`;
        display = this.$t(transKey);
        if (display === transKey) display = value;
      }

      return display;
    },
  },
};
</script>

<style lang="scss">
.input-autocomplete {
  width: 100%;
}
</style>
