<template>
  <div class="entity-modal entity-modal--action-form">
    <div class="entity-modal__wrap">
      <a-spin :spinning="isLoading" class="entity-modal__spin" />
      <entity-view
        v-if="formConfig"
        :type="type"
        :draftType="action"
        :extFormConfig="formConfig"
        :extMeta="fieldsMeta"
        :customTitle="title"
        :data="entityData"
        isModal
        isActionForm
        @editConfig="editConfig"
        @editEntity="reemit('editEntity', arguments)"
        @save="save"
      />
    </div>
  </div>
</template>

<script>
import store from '@/store';
import customMutation from '@/queries/mutations/customMutation';
import { bus, preparePayload, prepareFormConfig } from '@/helpers';
import EntityView from '../edit-form/EntityView.vue';

export default {
  name: 'ActionFormActions',
  components: {
    EntityView,
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    action: {
      type: String,
      default: '',
    },
    fieldsMeta: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      headMenu: false,
      isConfigEdit: false,
      isLoading: false,
      requestError: null,
      formConfig: prepareFormConfig({ fields: this.fieldsMeta }),
    };
  },

  computed: {
    entityData() {
      const data = { id: this.id };
      return (
        data && {
          ...data,
          ...(store.mutate.getFormDraft(this.action, this.id)?.data || {}),
        }
      );
    },
  },

  methods: {
    save(data) {
      this.isLoading = true;
      this.$apollo
        .mutate({
          mutation: customMutation(this.action, this.type, this.fieldsMeta),
          variables: preparePayload({ ...data }, this.fieldsMeta),
        })
        .then(() => {
          store.mutate.deleteFormDraft(this.action, this.id);
          this.isLoading = false;
          bus.$emit('refetchTable');
          this.$notification.success({
            message: this.$t('customForm.saveSuccess'),
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notification.error({
            message: this.$t('customForm.saveError'),
            description: error.message,
          });
        });
    },

    editConfig() {
      this.isConfigEdit = true;
    },
  },
};
</script>

<style lang="scss">
.entity-modal {
  &--action-form {
    height: auto;
    overflow-y: visible;
    .entity-form__footer {
      position: static;
      padding-bottom: 0;
    }
    .entity-form__content {
      padding-bottom: 0 !important;
    }
  }
}
</style>
