import { appSettings } from '@/AppSettings';

export function mediaUrl(path) {
  let url = `${appSettings.get('mediaUrl')}${path}`;
  if (!url.startsWith('http')) url = `${window.location.origin}${url}`;
  return url;
}

export function imageUrl(path, params = {}) {
  if (!params.width && !params.height) {
    return mediaUrl(path);
  }

  let query = [`url=${mediaUrl(path)}`, `fit=${params.fit || 'cover'}`];

  if (params.width) {
    query.push(`w=${params.width}`);
  }

  if (params.height) {
    query.push(`h=${params.height}`);
  }

  // Make 'url' parameter the last one. Otherwise Ant uploader won't show preview image
  query = `?${query.reverse().join('&')}`;
  return `${appSettings.get('resizerUrl')}${query}`;
}

export async function uploadFile(file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    const xhr = new XMLHttpRequest();

    formData.append('file', file);
    xhr.onreadystatechange = () => {
      if (xhr.readyState === XMLHttpRequest.DONE) {
        if (xhr.status === 200) {
          resolve(xhr.responseText);
        } else {
          reject(new Error('Uploading error'));
        }
      }
    };

    xhr.open('POST', appSettings.get('uploaderUrl'), true);
    xhr.send(formData);
  });
}
