class MetaQueryParser {
  prepareMeta(meta, user) {
    Object.values(meta.components).forEach((component) =>
      this.prepareMetaObject(component, meta, user),
    );

    Object.values(meta.embeds).forEach((embed) => this.prepareMetaObject(embed, meta, user));
    Object.values(meta.processes).forEach((process) => this.prepareMetaObject(process, meta, user));
    return meta;
  }

  prepareMetaObject(object, meta, user) {
    object.fields.forEach((field) => {
      if (!['component', 'embed'].includes(field.renderer)) return;

      const fieldIsEmbed = field.renderer === 'embed';
      const childDict = fieldIsEmbed ? 'embeds' : 'components';

      field.typesDict = field.types.reduce((dict, type) => {
        dict[type] = meta[childDict][type];
        return dict;
      }, {});
    });

    this.updateObjectOperations(object, user);
    return object;
  }

  updateObjectOperations(object, user) {
    object.operations = ['TABLE', 'READ', 'UPDATE', 'CREATE', 'DELETE'].reduce((list, opName) => {
      const opRule = object.securityRules.find((rule) => rule.operation === opName);
      const roles = opRule?.roles || [];
      list[opName] = !roles.length || roles.some((role) => user.roles.includes(role));
      return list;
    }, {});
  }

  updateOperations(meta, user) {
    Object.values(meta.components).forEach((comp) => this.updateObjectOperations(comp, user));
    Object.values(meta.embeds).forEach((embed) => this.updateObjectOperations(embed, user));
    Object.values(meta.processes).forEach((process) => this.prepareMetaObject(process, meta, user));
  }
}

export const metaQueryParser = new MetaQueryParser();
