const OPS = {
  ILIKE: { key: 'ILIKE' },
  EQUALS: { key: 'EQUALS' },
  GT: { key: 'GT' },
  LT: { key: 'LT' },
  NONEMPTY: { key: 'EQUALS' },
};

const renderersDict = [
  {
    renderer: 'string',
    applicableFormRenderers: ['string', 'text', 'wysiwyg', 'json'],
    operators: [OPS.ILIKE],
  },
  {
    renderer: 'checkbox',
    applicableFormRenderers: ['boolean'],
    operators: [OPS.EQUALS],
  },
  {
    renderer: 'checkbox',
    applicableFormRenderers: ['image', 'gallery', 'color', 'component', 'attachment', 'embed'],
    operators: [OPS.NONEMPTY],
  },
  {
    renderer: 'datetimepicker',
    applicableFormRenderers: ['date', 'date-time', 'time'],
    operators: [OPS.EQUALS, OPS.GT, OPS.LT],
  },
  {
    renderer: 'enum',
    applicableFormRenderers: ['handbook'],
    operators: [OPS.EQUALS],
  },
  {
    renderer: 'number',
    applicableFormRenderers: ['integer'],
    operators: [OPS.EQUALS, OPS.GT, OPS.LT],
    valueProcessor: (v) => Number(v),
  },
  {
    renderer: 'autocomplete',
    applicableFormRenderers: ['ref', 'refs'],
    operators: [OPS.EQUALS],
  },
  {
    renderer: 'autocomplete2',
    applicableFormRenderers: ['ref2'],
    operators: [OPS.EQUALS],
  },
  {
    renderer: 'autocomplete2',
    applicableFormRenderers: ['ref-like'],
    operators: [OPS.ILIKE],
  },
];

function getFilterRendererByFormRenderer(fieldRenderer) {
  return renderersDict.find(({ applicableFormRenderers }) =>
    applicableFormRenderers.includes(fieldRenderer),
  );
}

export { getFilterRendererByFormRenderer };
