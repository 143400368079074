<template>
  <div class="entity-page">
    <entity-view :id="id" :type="type" @goBack="goBack" />
  </div>
</template>

<script>
import EntityView from './EntityView.vue';

export default {
  name: 'EntityPage',
  components: {
    EntityView,
  },

  props: {
    type: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      browserHistoryDepth: 1000,
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === null) vm.browserHistoryDepth = 0;
      else vm.browserHistoryDepth++;
    });
  },

  methods: {
    goBack() {
      if (this.browserHistoryDepth > 0) {
        this.browserHistoryDepth--;
        this.$router.go(-1);
      } else {
        this.$router.push({
          name: 'DataPage',
          props: {
            type: this.type,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.entity-page {
  @include scrollbars();
  height: 100%;
  overflow-y: scroll;
}
</style>
