<template>
  <span v-if="isUniform" @click="emitClick(firstFormTypeKey)">
    <slot>
      <a-button class="entity-title-create-button">
        <a-icon type="plus" />
        <span>{{
          $t('embed.addElement_typed', { type: formTypeLabel(firstFormTypeKey, firstFormType) })
        }}</span>
      </a-button>
    </slot>
  </span>
  <a-dropdown v-else :trigger="['click']" :getPopupContainer="getPopupContainer">
    <a-menu slot="overlay" @click="emitClick($event.key)">
      <a-menu-item v-for="(formType, key) in config.typesDict" :key="key">
        {{ formTypeLabel(key, formType) }}
      </a-menu-item>
    </a-menu>
    <slot>
      <a-button class="entity-title-create-button">
        <a-icon type="plus" />
        <span>{{ $t('embed.addElement') }}</span>
      </a-button>
    </slot>
  </a-dropdown>
</template>

<script>
import store from '@/store';

export default {
  name: 'EditFormEmbedCreateButton',
  props: {
    config: {
      type: Object,
      required: true,
    },
    formConfigs: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      configsLoading: 0,
    };
  },
  computed: {
    isUniform() {
      return this.config.types.length === 1;
    },
    firstFormTypeKey() {
      return this.config.types[0];
    },
    firstFormType() {
      return this.config.typesDict[this.config.types[0]];
    },
  },
  methods: {
    emitClick(type) {
      this.$emit('click', type);
    },
    formTypeLabel(type, { name }) {
      return this.formConfigs[type].locale[store.state.lang].entity || name;
    },
    getPopupContainer() {
      return document.querySelector('body');
    },
  },
};
</script>
