import { parseJwt } from '@/helpers/dataProcessing';

const GUEST = {
  username: 'unauthorized',
  authorities: [],
};

export default class User {
  constructor(authToken) {
    const userData = authToken ? parseJwt(authToken) : GUEST;
    this.authorized = !!authToken;
    this.username = userData.username;
    this.roles = userData.authorities.concat('ROLE_AUTHOR');
    this.isConstructor = this.roles.includes('ROLE_CONSTRUCTOR');
    this.authToken = authToken;
  }

  setTokens({ token }) {
    this.authToken = token;
  }
}
