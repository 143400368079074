<template>
  <!-- eslint-disable vuejs-accessibility/label-has-for -->
  <div class="entity-config__params">
    <div class="entity-config__config entity-config__config--vertical" style="margin-bottom: 17px">
      <label>{{ $t('entity.config.tab.localization.entityName') }}</label>
      <a-input v-model="dictionary.entity" class="textinput" autocomplete="off" @input="update" />
    </div>
    <div
      class="entity-config__config entity-config__config--locale-head entity-config__config--wide"
    >
      <label>{{ $t('entity.config.tab.localization.field') }}</label>
      <label>{{ $t('entity.config.tab.localization.fieldName') }}</label>
      <label>{{ $t('entity.config.tab.localization.fieldInfo') }}</label>
    </div>
    <div
      v-for="descriptor of localizableFields"
      :key="descriptor.field"
      class="entity-config__config entity-config__config--locale entity-config__config--wide"
    >
      <label>
        {{ descriptor.name }}
      </label>
      <a-input
        v-model="dictionary.fields[descriptor.name].label"
        class="textinput"
        autocomplete="off"
        @input="update"
      />
      <a-input
        v-model="dictionary.fields[descriptor.name].info"
        class="textinput"
        autocomplete="off"
        @input="update"
      />
    </div>
  </div>
</template>

<script>
import { isSystemField } from '@/helpers';

export default {
  name: 'EntityFormConfigLocale',
  props: {
    value: {
      type: Object,
      required: true,
    },
    descriptors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    localizableFields() {
      return this.descriptors.filter((descriptor) => !isSystemField(descriptor));
    },
    dictionary() {
      return this.value;
    },
  },
  methods: {
    update() {
      this.$emit('input', this.dictionary);
    },
  },
};
</script>
