<template>
  <div :class="['attachment', { disabled, readOnly }]">
    <a-upload
      :beforeUpload="beforeUpload"
      :fileList="fileList"
      @change="handleChange"
      @preview="showPreview"
      @remove="remove"
    >
      <a-button v-if="!isFileAttached">
        <a-icon type="upload" />
        {{ $t('base.placeholder.upload') }}
      </a-button>
    </a-upload>
  </div>
</template>

<script>
import FocusOnMountMixin from '@/components/base/focusOnMount.mixin';
import { uploadFile, mediaUrl, uniqueId } from '@/helpers';

export default {
  name: 'InputAttachment',
  mixins: [FocusOnMountMixin],
  props: {
    value: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fileList: {
      get() {
        return (
          this.value && [
            {
              uid: this.value,
              name: this.value.replace(/^.*\//g, ''),
              url: this.value,
              urlFull: mediaUrl(this.value),
            },
          ]
        );
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    isFileAttached() {
      return this.fileList && this.fileList.length > 0;
    },
  },

  methods: {
    async setFocus() {
      // browser only allows to open file dialog in response on user generated events
      // exception -- freshly created input element, so here the hack

      if (this.fileList && this.fileList.length > 0) return;

      const element = document.createElement('input');
      element.type = 'file';
      element.onchange = this.onAutoInputChange;
      element.click();
    },
    onAutoInputChange({ target }) {
      const file = target.files[0];
      if (file) {
        file.uid = uniqueId(`fileupload`);
        this.handleChange({ fileList: [file] });
      } else {
        this.handleChange({ fileList: [] });
      }
    },
    showPreview() {
      window.open(this.fileList[0].urlFull, '_blank');
    },
    beforeUpload() {
      return false;
    },
    remove() {
      this.fileList = null;
    },
    async handleChange({ fileList }) {
      if (fileList.length > 0) {
        this.fileList = await uploadFile(fileList[0].originFileObj);
      } else {
        this.$emit('remove', this.fileList[0].uid);
        this.remove();
      }
    },
  },
};
</script>

<style lang="scss">
.attachment {
  max-width: 250px;

  .ant-upload-list-item:first-child {
    margin-top: 0;
    height: 24px;
  }

  &.disabled,
  &.readOnly {
    .anticon-delete {
      display: none;
    }
  }

  &[size='small'] .ant-btn {
    height: 24px;
  }
}
</style>
